@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;700&family=Roboto:wght@400;500;700&family=Work+Sans:ital,wght@0,400;0,600;0,700;1,400;1,700&display=swap');

body {
	overflow: hidden;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

::-webkit-scrollbar {
	padding: 5px;
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background: #ddd;
	border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
	background: #bbb;
}
